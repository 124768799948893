<template>
  <v-fab-transition>
    <v-btn
      color="#36b928"
      dark
      right
      fixed
      bottom
      fab
      :href="`https://wa.me//5511975386175?text=Preciso%20tirar%20dúvidas%20com%20a%20equipe%20do%20Simplificador`"
      target="_blank"
    >
      <v-icon large color="#ffffff">fab fa-whatsapp</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
import { authComputed } from '@state/helpers'

export default {
  components: {},
  props: {},
  data() {
    return {
      tagScript: '',
    }
  },
  computed: {
    ...authComputed,
    shwoWhatsapp() {
      return [2, 3, 4, 5].includes(this.currentUser.idRegistrationStatus)
    },
  },
  methods: {
    loadChat() {
      if (!this.shwoWhatsapp) {
        this.createTagScript()
        this.createOctadesck()
      }
    },

    createTagScript() {
      let chatOctadesk = document.getElementById('octadesk-octachat-appchat')
      if (chatOctadesk) chatOctadesk.remove()
      this.tagScript = document.createElement('script')
      this.tagScript.setAttribute('id', 'chats-simplificador')
      let firstElementTagScrip = document.getElementsByTagName('script')[0]
      firstElementTagScrip.parentNode.insertBefore(
        this.tagScript,
        firstElementTagScrip
      )
    },
    createOctadesck() {
      let userObj = Object.assign({}, this.currentUser)
      window.octadesk = window.octadesk || {}
      this.tagScript.src = 'https://cdn.octadesk.com/embed.js'
      window.octadesk.chatOptions = {
        subDomain: 'simplificador',
        showButton: true,
        openOnMessage: true,
        hide: false,
      }
      if (!!userObj) {
        const constUser = this.currentUser
        setTimeout(() => {
          window.octadesk.chat.login({
            name: constUser.name, // nome do seu usuário
            email: constUser.email, // email do seu usuário
          })
        }, 4000)
      }
    },
  },
  mounted() {
    this.loadChat()
  },
}
</script>

<style lang="sass" scoped></style>
