<template>
  <div :class="$style.container">
    <base-header-bar :logout="loggedIn"></base-header-bar>
    <v-main :class="$style.container" style="background: #f8f8f8;">
      <VRow justify="center" align="center" :class="$style.container">
        <slot />
      </VRow>
    </v-main>
    <chat />
  </div>
</template>
<script>
import { authComputed } from '@state/helpers'
import Chat from '@src/components/chats/chats.vue'

export default {
  components: {
    Chat,
  },
  computed: {
    ...authComputed,
  },
}
</script>
<style lang="scss" module>
@import '@design';
.container {
  height: 100%;
  width: 100%;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
}
</style>
