<template>
  <Layout>
    <v-col xl="6" md="7" cols="12">
      <v-card class="pa-4" elevation="3">
        <RouterView :key="$route.fullPath" />
      </v-card>
    </v-col>
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'

export default {
  name: 'new',
  components: {
    Layout,
  },
}
</script>

<style lang="scss">
// @import "../styles/components/example.scss";
.icon-color {
  color: #999999;
}
.s-color-grey {
  color: rgba(0, 0, 0, 0.67);
}
.s-title {
  font-size: 3rem;
}
.s-subtitle-1 {
  font-size: 1.125rem;
}
.s-matte-green {
  color: #9ebf9f;
}
.s-quiz-title {
  font-size: 1.6rem;
}
.s-quiz-subheading {
  font-size: 1.125rem;
}
</style>
